import { CONVERSATION_TYPE } from "../../../constants/chat";
import { EVENT_TYPES } from "../../../constants/events";
import { calculatePercentageSafely, calculatePercentageValue, maxSafe, minSafe } from "../utils";

export const calculateMetricsHelper = (records, events, costPerTicket, eventCounts) => {
    const totalSessions = records?.length || 0;
    let totalQueries = 0;
    let totalFeedback = 0;
    let totalRating = 0;
    let totalUserQueries = 0;
    let totalEscalationFalse = 0;
    for (let item of records) {
        totalQueries += minSafe(item?.analytics?.relevant_queries || 0, item?.analytics?.user_queries || 0);
        totalFeedback += (item?.feedback && item?.feedback?.rating) ? 1 : 0;
        totalRating += (item?.feedback?.rating || 0);
        totalUserQueries += (item?.analytics?.user_queries || 0);
        totalEscalationFalse += (item?.conversation_type === CONVERSATION_TYPE.CONVERSATION_TYPE_AI ? 1 : 0);
    }
    let mishitEvents = 0;
    let dislikedEvents = 0;
    let loadEvents = 0;
    const totalAutomations = eventCounts?.[EVENT_TYPES.WORKFLOW_START_EVENT] || 0;
    const workflowEnd = eventCounts?.[EVENT_TYPES.WORKFLOW_SUCCESS_EVENT] || 0;
    for (let item of events) {
        mishitEvents += item?.[EVENT_TYPES.MISHIT_EVENT] || 0;
        dislikedEvents += item?.[EVENT_TYPES.DISLIKE_EVENT] || 0;
        loadEvents += item?.[EVENT_TYPES.WIDGET_READY_EVENT] || 0;
    }

    const totalEscalation = totalSessions - totalEscalationFalse;
    const fcrPercentage = calculatePercentageSafely(totalEscalationFalse, totalSessions).toFixed(2);
    const totalCostSavings = ((isNaN(costPerTicket) ? 0 : costPerTicket) * calculatePercentageValue(fcrPercentage, totalSessions)).toFixed(2);

    const averageRating = totalFeedback !== 0 ? (totalRating / totalFeedback).toFixed(2) : 0;
    const engagementRateValue = calculatePercentageSafely(minSafe(totalSessions, loadEvents), loadEvents).toFixed(2);
    const queriesPerSession = totalSessions !== 0 ? (totalUserQueries / totalSessions).toFixed(2) : 0;
    const gaps = mishitEvents + dislikedEvents + totalEscalation;
    const trueQuestions = totalQueries !== 0 ? calculatePercentageSafely(totalQueries, totalUserQueries).toFixed(2) : 0;

    return {
        sessionsTotal: totalSessions,
        totalQueries: totalQueries,
        fcr: fcrPercentage,
        costSavings: totalCostSavings,
        csatScore: averageRating,
        engagementRate: engagementRateValue,
        gaps,
        trueQuestions,
        queriesPerSession,
        totalAutomations
    };
}

export const calculateMetricsByHourHelper = (records, events, costPerTicket, eventCounts) => {
    const totalSessions = records?.length || 0;
    let totalQueries = 0;
    let totalFeedback = 0;
    let totalRating = 0;
    let totalUserQueries = 0;
    let totalEscalationFalse = 0;
    for (let item of records) {
        totalQueries += minSafe(item?.analytics?.relevant_queries || 0, item?.analytics?.user_queries || 0);
        totalFeedback += (item?.feedback && item?.feedback?.rating) ? 1 : 0;
        totalRating += (item?.feedback?.rating || 0);
        totalUserQueries += (item?.analytics?.user_queries || 0);
        totalEscalationFalse += (item?.conversation_type === CONVERSATION_TYPE.CONVERSATION_TYPE_AI ? 1 : 0);
    }
    let mishitEvents = 0;
    let dislikedEvents = 0;
    const loadEvents = eventCounts[EVENT_TYPES.WIDGET_READY_EVENT];
    const totalAutomations = eventCounts?.[EVENT_TYPES.WORKFLOW_START_EVENT] || 0;
    const workflowEnd = eventCounts?.[EVENT_TYPES.WORKFLOW_SUCCESS_EVENT] || 0;
    for (let item of events) {
        if (item?.event_type === EVENT_TYPES.MISHIT_EVENT) mishitEvents++;
        else if (item?.event_type === EVENT_TYPES.DISLIKE_EVENT) dislikedEvents++;
    }
    const totalEscalation = totalSessions - totalEscalationFalse;
    const fcrPercentage = calculatePercentageSafely(totalEscalationFalse, totalSessions).toFixed(2);
    const totalCostSavings = ((isNaN(costPerTicket) ? 0 : costPerTicket) * calculatePercentageValue(fcrPercentage, totalSessions)).toFixed(2);

    const averageRating = totalFeedback !== 0 ? (totalRating / totalFeedback).toFixed(2) : 0;
    const engagementRateValue = calculatePercentageSafely(minSafe(totalSessions, loadEvents), loadEvents).toFixed(2);
    const queriesPerSession = totalSessions !== 0 ? (totalUserQueries / totalSessions).toFixed(2) : 0;
    const gaps = mishitEvents + dislikedEvents + totalEscalation;
    const trueQuestions = totalQueries !== 0 ? calculatePercentageSafely(totalQueries, totalUserQueries).toFixed(2) : 0;

    return {
        sessionsTotal: totalSessions,
        totalQueries: totalQueries,
        fcr: fcrPercentage,
        costSavings: totalCostSavings,
        csatScore: averageRating,
        engagementRate: engagementRateValue,
        gaps,
        trueQuestions,
        queriesPerSession,
        totalAutomations
    };
};
