// Packages:
import React, { useState, useEffect, useCallback, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
// Imports:

import {
  TextData,
  Filterv2,
  HistogramPage,
  CircularPage,
} from "../../components/Analytics";

// Styles:
import { Container, Wrapper, Options } from "./styles";
import { clearTableChatRecords, getChatActions } from "../../redux/actions/chatActions";
import { useDeepCompareEffect } from "react-use/lib";
import { clearEventsCount, clearTableReactionRecords, getContentActions } from "../../redux/actions/contentActions";
import { getRequestTypes } from "../../components/Analytics/utils";
import { selectSessionItems } from "../../redux/selectors/chatSelectors";
import { CHART_CHAT_FILTER_PARAMS } from "../../constants/api";
import { ISO_DATE_FORMAT } from "../../constants/misc";
import { EVENT_TYPES, SUB_EVENTS } from "../../constants/events";

const fetchEvents = [
  { eventType: EVENT_TYPES.WIDGET_READY_EVENT, subType: EVENT_TYPES.WIDGET_READY_EVENT },
  { eventType: [EVENT_TYPES.WORKFLOW_START_EVENT, EVENT_TYPES.WORKFLOW_SUCCESS_EVENT], subType: SUB_EVENTS.WORKFLOW_SUB_EVENT },
]
// Functions:
const InfoV3 = () => {
  const avoidInitialDateRange = useRef(false); 
  // State:
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch);
  const contentActions = getContentActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const chatRecords = useSelector(selectSessionItems, shallowEqual);
  const orgid = organisation_info?.org_data?._id;
  const reactionRecords = useSelector((state) => state.content.reactionRecords);
  const [stat, setStat] = useState("");
  const personas = useSelector(state => state.content.personas);
  const allPersona = [...new Set(["All", ...personas])]
  const [persona, setPersona] = useState(allPersona);
  const profiles = useSelector(state => state.content.profiles);
  const allProfiles = [...new Set(["All", ...profiles])];
  const [profile, setProfile] = useState(allProfiles);
  const [dateRange, setDateRange] = useState(sessionStorage.getItem('dateRange') || "this week");
  const [startDate, setStartDate] = useState(sessionStorage.getItem('startDate') || moment().startOf('day').subtract(7, "days").format(ISO_DATE_FORMAT));
  const [endDate, setEndDate] = useState(sessionStorage.getItem('endDate') || moment().endOf('day').format(ISO_DATE_FORMAT));
  const [conversationType, setConversationType] = useState([]);
  const [type, setType] = useState([]);
  const [hideInfo, setHideInfo] = useState(true);
  const [records, setRecords] = useState(chatRecords);
  const isContentLoading = useSelector((state) => state.chat.loading);
  const [topicFilters, setTopicFilters] = useState({
    sentiment: [],
    outcome: [],
    ...JSON.parse(sessionStorage.getItem('topicFilters') || '{"sentiment":[],"outcome": []}')
  });

  useEffect(() => {
    dispatch(clearTableChatRecords());
    dispatch(clearTableReactionRecords());
    let { chatRequestType, eventRequestType } = getRequestTypes(startDate, endDate)
    const diff = moment(endDate).diff(startDate, 'days');
    let updatedEvents = fetchEvents;
    if (diff > 1){
      updatedEvents = fetchEvents.filter(event => event.eventType !== EVENT_TYPES.WIDGET_READY_EVENT);
    }
    contentActions.fetchEventCountBasedOnTypes(orgid, startDate, endDate, updatedEvents,  { unique: true, filters: JSON.stringify({ personas: persona, profiles: profile }) } );
    chatActions.fetchChatRecordsV3(
      orgid,
      startDate || moment().startOf('day').subtract(7, "days").format(ISO_DATE_FORMAT),
      endDate || moment().endOf('day').format(ISO_DATE_FORMAT),
      chatRequestType,
      CHART_CHAT_FILTER_PARAMS
    ); //change it for start and end date

    contentActions.fetchReactionsV2(
      orgid,
      startDate || moment().startOf('day').subtract(7, "days").format(ISO_DATE_FORMAT),
      endDate || moment().endOf('day').format(ISO_DATE_FORMAT),
      eventRequestType
    );

  }, [orgid]);

  useDeepCompareEffect(() => {
    const diff = moment(endDate).diff(startDate, 'days');
    let filteredRecords = chatRecords;
    if(diff <= 1){
      const personaFilters = {
        'Agent': record => record.user_email !== 'anonymous',
        'Customer': record => record.user_email === 'anonymous',
        'Default': {
          [persona]: record => record?.user_personas?.includes(persona),
        }
      };
      if (persona in personaFilters) {
        filteredRecords = filteredRecords?.filter(personaFilters[persona]);
      }
      else if(personas.includes(persona)){
        filteredRecords = filteredRecords?.filter(personaFilters['Default'][persona]);
      }
    }
    else{
      if(persona in personas){
        filteredRecords = filteredRecords.filter(record => record?.user_personas?.hasOwnProperty(persona));
      }
    }

    setRecords(filteredRecords);
  }, [chatRecords, persona]);

  // Event Handlers:

  const handleDateRangeChange = useCallback(
    (event) => setDateRange(event.target.value),
    []
  );

  const handlePersonaChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    
    const allPersonaMarked = persona.includes("All");
    
    // const val = typeof value === 'string' ? value.split(',') : value;
    if(allPersonaMarked && !value?.includes("All")){
      setPersona([]);
    }
    else if(allPersonaMarked) {
      setPersona(value.filter(val => val?.toLowerCase() !== "all"));
    }
    else if(!allPersonaMarked && ((value?.length === allPersona.length - 1) || value?.includes("All"))){
      setPersona(allPersona);
    }
    else {
      setPersona(value);
    }
  }, [persona, allPersona]);

  const handleProfileChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    
    const allMarked = profile.includes("All");
    
    // const val = typeof value === 'string' ? value.split(',') : value;
    if(allMarked && !value?.includes("All")){
      setProfile([]);
    }
    else if(allMarked) {
      setProfile(value.filter(val => val?.toLowerCase() !== "all"));
    }
    else if(!allMarked && ((value?.length === allProfiles.length - 1) || value?.includes("All"))){
      setProfile(allProfiles);
    }
    else {
      setProfile(value);
    }
  }, [profile, allProfiles]);

  const handleStartDateChange = useCallback((newValue) => {
    setDateRange('custom')
    let momentformattedStartDate = newValue;
    if (!moment.isMoment(newValue)) {
      momentformattedStartDate = moment(newValue).startOf('day');
    }
    let formattedStartDate = momentformattedStartDate.format(
      ISO_DATE_FORMAT
    );
    setStartDate(formattedStartDate);
  }, []);

  const handleEndDateChange = useCallback((newValue) => {
    setDateRange('custom')
    let momentformattedEndDate = newValue;
    if (!moment.isMoment(newValue)) {
      momentformattedEndDate = moment(newValue).endOf('day');
    }
    let formattedEndDate = momentformattedEndDate.format(
      ISO_DATE_FORMAT
    );
    setEndDate(formattedEndDate);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('dateRange', dateRange)
    sessionStorage.setItem('startDate', startDate);
    sessionStorage.setItem('endDate', endDate);
  }, [startDate, endDate, dateRange])

  useEffect(() => {
    if(!avoidInitialDateRange.current){
      dispatch(clearTableChatRecords());
      dispatch(clearTableReactionRecords());
      dispatch(clearEventsCount());
      avoidInitialDateRange.current = true;
      return;
    }
    // <StyledMenuItem value="custom" key="custom">Custom</StyledMenuItem>
    let formatType = "days", byFormat = 1;
    switch(dateRange){
      case "this year":
        formatType = "years";
        break;
      case "this month":
        formatType = "months";
        break;
      case "this week":
        byFormat = 7;
        break;
      case "today":
        byFormat = 0;
        break;
    }
    if(dateRange != "custom"){
      setStartDate(
        moment().startOf('day').subtract(byFormat, formatType).format(ISO_DATE_FORMAT)
      );
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT));
    }
  }, [dateRange]);
  const [costPerTicket, setcostPerTicket] = useState(6.00);
  const handlecostPerTicket = useCallback(data => {
    setcostPerTicket(parseFloat(data >= 0 && !isNaN(data) ? data : 0).toFixed(2));
    sessionStorage.setItem('costPerTicket', parseFloat(data && !isNaN(data) >= 0 ? data : 0).toFixed(2));
  }, []); //minimum value of ticket-cost

  return (
    <Container>
      <h3 style={{ textAlign: "center", marginTop: "-2rem" }}>Dashboard</h3>
      <Options>
        <Filterv2
          handleDateRangeChange={handleDateRangeChange}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handlePersonaChange={handlePersonaChange}
          handleProfileChange={handleProfileChange}
          startDate={startDate}
          endDate={endDate}
          dateRange={dateRange}
          persona={persona}
          profile={profile}
          tab="records"
          conversationType={conversationType}
          orgId={orgid}
        />
      </Options>
      <Wrapper>
        <>
          <TextData
            records={records}
            hideInfo={hideInfo}
            startDate={startDate}
            endDate={endDate}
            handlecostPerTicket={handlecostPerTicket}
            costPerTicket={costPerTicket}
            persona={persona}
            profile={profile}
          />
          <HistogramPage
            startDate={startDate}
            endDate={endDate}
            dateRange={dateRange}
            hideInfo={hideInfo}
            conversationType={conversationType}
            costPerTicket={costPerTicket}
            persona={persona}
            profile={profile}
            topicFilters={topicFilters}
            setTopicFilters={setTopicFilters}
          />
          <CircularPage
            stat={stat}
            reactions={reactionRecords}
            startDate={startDate}
            endDate={endDate}
            dateRange={dateRange}
            type={type}
            hideInfo={hideInfo}
            persona={persona}
            profile={profile}
            topicFilters={topicFilters}
          />
        </>
      </Wrapper>
    </Container>
  );
};

// Exports:
export default InfoV3;
