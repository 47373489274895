import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { clearTableChatRecords } from '../redux/actions/chatActions';
import { clearEventsCount, clearTableReactionRecords } from '../redux/actions/contentActions';
import { ISO_DATE_FORMAT, MINUTE_DATE_FORMAT } from '../constants/misc';

toast.configure();

const useFilterHook = ({ startDate, endDate, dateRange, handleStartDateChange, handleEndDateChange, handleDateRangeChange, orgId, persona, profile }) => {
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const orgIdRef = useRef(orgId);
    const dateRangeRef = useRef(dateRange);
    const personaRef = useRef(persona);
    const profileRef = useRef(profile);
    const [applyFilterDisable, setApplyFilterDisable] = useState(true);
    const dispatch = useDispatch();

    const handleStartDateChangeEnhanced = useCallback((newValue) => {
        if (new Date(newValue).getTime() > new Date(endDate).getTime()) {
            toast.error("Start date should be earlier than end date.", { position: 'top-right' });
            return;
        }
        // if (startDateRef.current.valueOf() !== moment(newValue).startOf('day').format(ISO_DATE_FORMAT).valueOf()) {
        //     setApplyFilterDisable(false);
        // } else {
        //     setApplyFilterDisable(true);
        // }
        handleStartDateChange(newValue);
    }, [endDate, handleStartDateChange]);

    const handleEndDateChangeEnhanced = useCallback((newValue) => {
        if (new Date(newValue).getTime() < new Date(startDate).getTime()) {
            toast.error("End date should be later than start date.", { position: 'top-right' });
            return;
        }
        // if (moment(endDateRef.current).format(MINUTE_DATE_FORMAT).valueOf() !== moment(newValue).endOf('day').format(MINUTE_DATE_FORMAT).valueOf()) {
        //     setApplyFilterDisable(false);
        // } else {
        //     setApplyFilterDisable(true);
        // }
        handleEndDateChange(newValue);
    }, [startDate, handleEndDateChange]);

    const applyFilterFuncExecution = (callback) => {
        if (startDateRef.current.valueOf() !== startDate.valueOf() ||
            endDateRef.current.valueOf() !== endDate.valueOf() ||
            orgIdRef.current !== orgId ||
            JSON.stringify(personaRef.current) !== JSON.stringify(persona) ||
            JSON.stringify(profileRef.current) !== JSON.stringify(profile)
        ) {
            dispatch(clearTableChatRecords());
            dispatch(clearTableReactionRecords());
            dispatch(clearEventsCount());
            callback();
            startDateRef.current = startDate;
            endDateRef.current = endDate;
            orgIdRef.current = orgId;
            dateRangeRef.current = dateRange;
            personaRef.current = persona;
            profileRef.current = profile;
            setApplyFilterDisable(true);
        }
    };

    const handleDateRangeChangeEnhanced = useCallback((event) => {
        // if (dateRangeRef.current === event.target.value || event.target.value === "custom") {
        //     setApplyFilterDisable(true);
        // }
        // else {
        //     setApplyFilterDisable(false);
        // }
        handleDateRangeChange(event);
    }, [])

    useEffect(() => {
        if (startDateRef.current.valueOf() !== startDate.valueOf() ||
            endDateRef.current.valueOf() !== endDate.valueOf() ||
            orgIdRef.current !== orgId ||
            JSON.stringify(personaRef.current) !== JSON.stringify(persona) ||
            JSON.stringify(profileRef.current) !== JSON.stringify(profile)
        ) {
            setApplyFilterDisable(false);
        }
        else {
            setApplyFilterDisable(true);
        }
    }, [startDate, endDate, persona, profile, startDateRef, endDateRef, personaRef, profileRef, orgId, orgIdRef]);

    return {
        handleStartDateChange: handleStartDateChangeEnhanced,
        handleEndDateChange: handleEndDateChangeEnhanced,
        handleDateRangeChange: handleDateRangeChangeEnhanced,
        applyFilterFuncExecution,
        applyFilterDisable
    };
};

export default useFilterHook;
