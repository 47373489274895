const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const PERFORMANCE_OVERVIEW_TOOLTIPS = {
    engagement_rate: 'Number Of Times Widget Expanded: (sessionRecordsCount / loadEvents)',
    queries_per_session: 'User Queries Per Session: (totalUserQueries / totalsession)',
    first_contact_resolution: 'Tickets Successfully Resolved By AI: (Resolved Sessions / totalSession) %',
    cost_savings: 'Cost Savings: ((fcr% * totalSession) * costPerTicket)',
    csat_score: 'Customer Satisfaction Score: (totalRating / totalFeedback)',
    gaps: 'Gaps: (Dislike + Escalation + No Answer)',
    true_questions: 'Average Relevant Questions: (relevantQueries / totalQueries)',
    total_automations: 'Total Workflow Operations'
};

export const COMMON_TOOLTIPS = {
    Created: timezone,
}

export const GAPS_TOOLTIPS = {
    ...COMMON_TOOLTIPS,
    Query: 'Query which lead to this Gap',
    Category: 'Category based on the Query',
    GAP_TYPE_TOOLTIPS: {
        NO_ANSWER: 'No response received',
        NOT_HELPFUL: 'Connected with Agent due to unsatisfactory answer',
        HELP_REQUESTED: 'Directly initiated Agent Assistance',
        DISLIKE: 'Expressed dissatisfaction with the service'
    },
}

export const SESSION_TOOLTIPS = {
    ...COMMON_TOOLTIPS,
    Queries: 'Top 3 User Queries of Session Logs',
    Topics: 'Topics/Categories based on conversation'
};

export const EVENTS_TOOLTIPS = {
    ...COMMON_TOOLTIPS
}

export const FEEDBACK_TOOLTIPS = {
    ...COMMON_TOOLTIPS
}