// Packages:
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Tabs, Tab, Tooltip, IconButton, Button } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {
  Information,
  TrackingInformation,
  TrackingTable,
  FeedbackInformation,
  // FeedbackTable,
} from '../../components';
import FeedbackTable from './FeedbackTable'
import Filters from './Filters'
import Table from './Table'

// Styles:
import { Container, Wrapper, Options } from './styles';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import GapsTable from './GapsTable';
import GapsInformation from './GapsTable/GapsInformation/GapsInformation';
import { clearTableChatRecords } from '../../redux/actions/chatActions';
import { clearEventsCount, clearTableReactionRecords } from '../../redux/actions/contentActions';
import { ISO_DATE_FORMAT } from '../../constants/misc';

// Functions:
const InfoV2 = () => {
  // check if component did mount
  const didMount = useRef(false);

  // State:
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organisation_info = useSelector((state) => state.content.org_info);
  const orgid = organisation_info?.org_data?._id;

  const [chat, setChat] = useState('');
  const [stat, setStat] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState([]);
  const [switchInfo, setSwitchInfo] = useState('records');
  const [dateRange, setDateRange] = useState( sessionStorage.getItem('dateRange') || 'this week');
  const [startDate, setStartDate] = useState( sessionStorage.getItem('startDate') || moment().startOf('day').subtract(7, 'days').format(ISO_DATE_FORMAT));
  const [endDate, setEndDate] = useState( sessionStorage.getItem('endDate') || moment().endOf('day').format(ISO_DATE_FORMAT));
  const profiles = useSelector(state => state.content.profiles);
  const allProfiles = [...new Set(["All", ...profiles])];
  const [profile, setProfile] = useState(allProfiles);

  const [type, setType] = useState([]);
  const [hideInfo, setHideInfo] = useState(true);
  const handleButtonClick = () => {
    navigate(ROUTES.AGENT.DASHBOARD);
  };

  useEffect(() => {
    dispatch(clearTableChatRecords());
    dispatch(clearTableReactionRecords());
  }, [])

  // Event Handlers:
  const handleChat = useCallback((data) => setChat(data), []);
  const handleStat = useCallback((data) => setStat(data), []);
  const handleFeedback = useCallback((data) => setFeedback(data), []);
  const handleDateRangeChange = useCallback((event) => setDateRange(event.target.value), []);

  // const handleStartDateChange = useCallback((newValue) => setStartDate(newValue), []);
  const handleStartDateChange = useCallback((newValue) => {
    setDateRange('custom');
    let momentformattedStartDate = newValue;
    if(!moment.isMoment(newValue)){
      momentformattedStartDate = moment(newValue).startOf('day');
    }
    let formattedStartDate = momentformattedStartDate.format(ISO_DATE_FORMAT);
    setStartDate(formattedStartDate);
  }, []);
  
  const handleEndDateChange = useCallback((newValue) => {
    setDateRange('custom');
    let momentformattedEndDate = newValue;
    if(!moment.isMoment(newValue)){
      momentformattedEndDate = moment(newValue).endOf('day');
    }
    let formattedEndDate = momentformattedEndDate.format(ISO_DATE_FORMAT);
    
    setEndDate(formattedEndDate);
  }, []);


  const handleRatingChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setRating(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }, []);

  const handleProfileChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    
    const allMarked = profile.includes("All");
    
    // const val = typeof value === 'string' ? value.split(',') : value;
    if(allMarked && !value?.includes("All")){
      setProfile([]);
    }
    else if(allMarked) {
      setProfile(value.filter(val => val?.toLowerCase() !== "all"));
    }
    else if(!allMarked && ((value?.length === allProfiles.length - 1) || value?.includes("All"))){
      setProfile(allProfiles);
    }
    else {
      setProfile(value);
    }
  }, [profile, allProfiles]);

  const handleTypeChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }, []);

  useEffect(() => {
    sessionStorage.setItem('dateRange', dateRange)
    sessionStorage.setItem('startDate', startDate);
    sessionStorage.setItem('endDate', endDate);
  }, [startDate, endDate, dateRange])

  useEffect(() => {
    // skip running useffect on initial render
    if(!didMount.current){
      didMount.current = true;
      return;
    }
    if (dateRange === 'this month') {
      setStartDate(moment().startOf('day').subtract(1, 'months').format(ISO_DATE_FORMAT));
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT));
    } else if (dateRange === 'last 2 weeks') {
      setStartDate(moment().startOf('day').subtract(14, 'days').format(ISO_DATE_FORMAT));
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT));
    } else if (dateRange === 'yesterday') {
      setStartDate(moment().startOf('day').subtract(1, 'days').format(ISO_DATE_FORMAT));
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT));
    } else if (dateRange === 'today'){
      setStartDate(moment().startOf('day').format(ISO_DATE_FORMAT));
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT));
    } else if (dateRange === "this week") {
      setStartDate(moment().startOf('day').subtract(7, 'days').format(ISO_DATE_FORMAT));
      setEndDate(moment().endOf('day').format(ISO_DATE_FORMAT)); // 7 Days
    }
  }, [dateRange]);

  // Return:
  return (
    <Container>
      <Options>
        <Button onClick={handleButtonClick}>
          <ArrowBack style={{ fontSize: 'medium' }} />
          <span style={{ textTransform: 'capitalize' }}>Back To Dashboard</span>
        </Button>
        <Tabs
          value={switchInfo}
          onChange={(_, newValue) => {
            dispatch(clearTableChatRecords());
            dispatch(clearTableReactionRecords());
            setSwitchInfo(newValue);
          }}
        >
          <Tab value="records" label="Sessions" sx={{ textTransform: 'none' }} />
          <Tab value="tracking" label="Events" sx={{ textTransform: 'none' }} />
          <Tab value="feedback" label="Feedback" sx={{ textTransform: 'none' }} />
          <Tab value="gaps" label="Gaps" sx={{ textTransform: 'none' }} />
        </Tabs>
        {hideInfo ? (
          <Tooltip title="Show Details">
            <IconButton sx={{ ml: '1rem' }} onClick={() => setHideInfo(false)}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Hide Details">
            <IconButton sx={{ ml: '1rem' }} onClick={() => setHideInfo(true)}>
              <VisibilityOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        <Link to={ROUTES.AGENT.DATA} style={{color:'6a6a6a',margin:15,fontWeight:500}}>V1 Records</Link>
      </Options>
      <Wrapper>
        {switchInfo === 'records' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              tab="records"
              handleProfileChange={handleProfileChange}
              orgId={orgid}
              profile={profile}
              />
            <Table
              handleChat={handleChat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              profile={profile}
              />
            <Information chat={chat} hideInfo={hideInfo} startDate={startDate} endDate={endDate} />
          </>
        )}
        {switchInfo === 'tracking' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              tab="tracking"
              type={type}
              handleTypeChange={handleTypeChange}
              orgId={orgid}
              />
            <TrackingTable
              handleStat={handleStat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              type={type}
              setSwitchInfo={setSwitchInfo}
              />
            <TrackingInformation
              stat={stat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              type={type}
              hideInfo={hideInfo}
              />
          </>
        )}
        {switchInfo === 'feedback' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              rating={rating}
              handleRatingChange={handleRatingChange}
              tab="feedback"
              orgId={orgid}
            />
            <FeedbackTable
              handleFeedback={handleFeedback}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              rating={rating}
            />
            <FeedbackInformation 
              feedback={feedback} 
              hideInfo={hideInfo}
            />
          </>
        )}
        {switchInfo === 'gaps' && (
          <>
            <Filters 
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              tab="gaps"
              orgId={orgid}
            />
            <GapsTable 
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}     
              handleStat={handleStat} 
            />
            <GapsInformation 
              stat={stat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              type={type}
              hideInfo={hideInfo}
            />
          </>
        )}
      </Wrapper>
    </Container>
  );
};

// Exports:
export default InfoV2;
